<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div style="width: 100%;margin-top:10px">
          <el-form-item label="站点名称" prop="shop_name">
            <el-input v-model.trim="params.shop_name" placeholder="请输入站点名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="状态" class="col-auto">
            <el-select style="width: 100px" v-model="params.certification_status" size="small" placeholder="请选择"
              clearable>
              <el-option label="全部" value="" />
              <el-option label="未认证" :value="0" />
              <el-option label="审核中" :value="1" />
              <el-option label="已认证" :value="2" />
              <el-option label="已拒绝" :value="3" />
            </el-select>
          </el-form-item>
          <el-form-item label="业务员" prop="clerk_name">
            <el-input v-model.trim="params.clerk_name" placeholder="请输入业务员姓名" clearable></el-input>
          </el-form-item>
          <el-form-item label="创建时间" class="col-auto">
            <el-date-picker style="width: 220px" v-model="order_time_range" type="daterange" align="center"
              size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期" @change="handleDateChange"></el-date-picker>
          </el-form-item>

          <el-form-item class="col-auto">
            <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
          </el-form-item>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="站点名称">
          <template slot-scope="scope">{{ scope.row.shop_name }}</template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">{{ scope.row.qualification_certification_status | account_type_filter
            }}</template>
        </el-table-column>
        <el-table-column label="业务员">
          <template slot-scope="scope">
            <span>{{ scope.row.salesman_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" width="160">
          <template slot-scope="{row}">{{ row.shop_createtime | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="提交人">
          <template slot-scope="scope">
            <span>{{ scope.row.certification_by_name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="{row}">
            <el-button v-if="row.qualification_certification_status == 1" size="mini" type="primary"
              @click="edit(row)">审核</el-button>
            <el-button v-if="row.qualification_certification_status != 0" size="mini" type="primary"
              @click="info(row)">查看</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--认证审核-->
    <el-dialog-x-dialog title="认证审核" :visible.sync="editVisible">
      <el-form class="setting_form" :model="txInfo" ref="txInfo" :rules="txRules" label-width="135px">
        <el-form-item label="审核" prop="audit_status">
          <el-radio-group v-model="txInfo.audit_status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="0">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="审核备注" :error="errorMsg" :prop="txInfo.audit_status == 0 ? 'audit_remark' : ''">
          <el-input type="textarea"  maxlength="250" :autosize="{ minRows: 4, maxRows: 8 }" placeholder="请输入备注信息，不超过250字"
            v-model="txInfo.audit_remark"></el-input>
        </el-form-item>
        <div style="text-align: center;margin: 20px 0;">
          <el-button @click="cancel" size="small">取 消</el-button>
          <el-button type="primary" size="small" @click="accountSubmit">确 定</el-button>
        </div>
      </el-form>
    </el-dialog-x-dialog>
    <!--查看商品数量，商品展示-->
    <el-dialog-x-dialog title="认证详情" :visible.sync="inofVisible">
      <el-form class="setting_form" :model="refundInfo">
        <el-form-item label="公司名称" label-width="120px">
          {{ refundInfo.company_name }}
        </el-form-item>
        <el-form-item label="纳税人识别号" label-width="120px">
          {{ refundInfo.taxes_distinguish_num }}
        </el-form-item>
        <el-form-item label="营业执照" label-width="120px">
          <el-image style="width: 100px; height: 100px" @click="handlePreview(refundInfo.licence_img)"
            :src="refundInfo.licence_img"></el-image>
        </el-form-item>
        <el-form-item label="公司地址" label-width="120px">
          {{ refundInfo.company_address }}
        </el-form-item>
        <el-form-item label="联系人" label-width="120px">
          {{ refundInfo.link_name }}
        </el-form-item>
        <el-form-item label="联系电话" label-width="120px">
          {{ refundInfo.link_phone }}
        </el-form-item>
        <el-form-item label="开户银行" label-width="120px">
          {{ refundInfo.bank_name }}
        </el-form-item>
        <el-form-item label="银行账户" label-width="120px">
          {{ refundInfo.bank_number }}
        </el-form-item>
      </el-form>
    </el-dialog-x-dialog>
    <!-- 图片放大 -->
    <el-dialog :visible.sync="dialogImage">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import XDialog from '@/components/x-dialog/x-dialog';
import * as API_account from '@/api/account';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';

export default {
  name: 'goodsList',
  components: { EnTableLayout, XDialog },
  data () {
    return {
      dialogImageUrl: '',
      dialogImage: false,
      errorMsg: '',
      editVisible: false,//审核页
      inofVisible: false,//详情页
      refundInfo: {},//详情内容
      order_time_range: [],
      /** 列表loading状态 */
      loading: false,
      txInfo: {//审核表单内容
        audit_status: 1,
        audit_remark: ''
      },
      txRules: {//审核表单验证
        audit_remark: [{
          required: true,
          message: '审核备注不得为空',
          trigger: 'blur'
        }],
      },
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        certification_status: ''
      },
      /** 列表数据 */
      tableData: {},
      /** 店铺信息 */
      shopInfo: this.$store.getters.shopInfo,
    };
  },
  filters: {
    /**认证状态 */
    account_type_filter: value => {
      switch (value) {
        case 0:
          return '未认证';
        case 1:
          return '审核中';
        case 2:
          return '已认证';
        case 3:
          return '已拒绝';
      }
    }
  },
  mounted () {
    this.GET_AccountList();
  },
  methods: {
    /** 点击 放大 */
    handlePreview (url) {
      this.dialogImageUrl = url;
      this.dialogImage = true;
    },
    handleDateChange () {
      if (
        this.order_time_range &&
        this.order_time_range.length
      ) {
        if (typeof this.order_time_range[0] === "string") {
          this.params.start_create_time =
            new Date(this.order_time_range[0]).getTime() / 1000;
          this.params.end_create_time =
            new Date(this.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_create_time =
            this.order_time_range[0].getTime() / 1000;
          this.params.end_create_time =
            this.order_time_range[1].getTime() / 1000 + 86400;
        }
      } else {
        this.params.start_create_time = "";
        this.params.end_create_time = "";
      }

    },
    /** 搜索事件触发 */
    searchEvent () {
      this.params.page_no = 1;
      this.GET_AccountList();
    },
    /**关闭审核认证弹窗 */
    cancel () {
      this.editVisible = false//审核页
      this.inofVisible = false//详情页
    },
    /**打开认证审核 */
    edit (row) {
      this.editVisible = true;
      this.txInfo.shop_id = row.shop_id;
    },
    /**打开查看 */
    info (row) {
      API_account.getShopDetail(row.shop_id).then((response) => {
        this.refundInfo = {};
        this.refundInfo = response
        this.inofVisible = true
      })
    },
    /**提交认证审核 */
    accountSubmit () {
      this.txInfo.audit_status == 0 && this.txInfo.audit_remark == '' ? this.errorMsg = '审核备注不得为空' : this.errorMsg = ''
      this.$refs.txInfo.validate(valid => {
        if (valid && this.errorMsg == '') {
          API_account.auditShopCertification(this.txInfo).then((response) => {
            this.$message.success('提交成功');
            this.editVisible = false;
            this.GET_AccountList();
          })
        }
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    GET_AccountList () {
      this.loading = true;
      let params = this.params;
      API_account.getShopCertificationList(params).then(response => {
        this.loading = false;
        this.tableData = response;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

/deep/ .setting_form {
  .el-form-item {
    margin-bottom: 0px
  }
}
/deep/ .el-textarea {
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  overflow: hidden;
}
/deep/ .el-textarea__inner {
  min-height: 110px;
  border-radius: 0;
  border: none;
}
</style>
